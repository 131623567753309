<template>
	<div class="department-list">

		<el-card style="margin-top: 20px;" shadow="never">
			<el-row justify="space-between" type="flex">
				<el-col><i class="el-icon-tickets"></i><span>科室管理列表</span></el-col>
				<el-col style="display: flex;justify-content: flex-end;">
					<el-button type="primary" size="small" @click="getlist()">
						查询
					</el-button>
					<el-button  type="success" size="small" @click="goAdd('add')">
						新增
					</el-button>
				</el-col>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px;" shadow="never">
			<el-table :data="list" style="width: 100%;">
				<!-- <el-table-column type="selection" width="55" align="center">
				</el-table-column> -->
				<el-table-column label="排序" align="center">
					<template slot-scope="scope">
						<el-input style="width: 80px;" v-model="scope.row.sort" @blur="blur(scope)"></el-input>
					</template>
				</el-table-column>

				<el-table-column label="科室名称" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.deptName }}</span>
					</template>
				</el-table-column>
<!--        <el-table-column label="父科室名称" align="center" prop="parentName"/>-->

				<el-table-column label="显示状态" align="center">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.isShow" @change="switchbtn(scope)">
						</el-switch>
					</template>
				</el-table-column>

				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<p>
							<el-button size="mini" type="text" @click="goAdd('edit',scope)">修改</el-button>
							<el-button size="mini" type="text" @click="goAdd('sub',scope)" v-if="scope.row.parentNo == '0'">添加子科室</el-button>
							<el-button size="mini" type="text" @click="goSche(scope.row.deptNo,scope.row.deptName)" v-if="scope.row.parentNo == '0'">查看排班</el-button>
                            <el-button size="mini" type="text" @click="del(scope.row.deptNo)" >删除</el-button>
						</p>

					</template>
				</el-table-column>
			</el-table>
		</el-card>

		<el-pagination v-if="pageshow" :hide-on-single-page="hideonsinglepage" background :current-page="pageNo"
		 @current-change="pagebtn" :total="box.totalCount" :page-size="10" layout="prev, pager, next">
		</el-pagination>
	</div>
</template>

<script>

	export default {
		data() {
			return {
				keyword: '', //搜索
				list: [], //循环列表 
				row: { //点击选中的
					plugAdtypeId: '',
					plugAdtypeName: '',
					plugAdtypeOrder: '',
				},
				box: [], //全部数据
				hideonsinglepage: false, //分页
				allocDialogVisible: false, //控制弹窗显示
				modalAppendToBody: false, //弹窗插入位置
				apiurl: '', //接口地址
				pageNo: 1, //页码
				pageshow: false
			}
		},
		methods: {
			
			// 查询
			getonelist(){
				const that=this;
				that.list=[];
				that.pageNo=1;
				that.getlist();
			},
			
			//跳转添加修改页
			goAdd(action,e){
				
				const that = this;
				that.$router.push({name: 'departmentadd',query:{id: e ?  e.row.deptNo : '',ac:action,list:this.list}});
				
			},
		
			// 单条排序
			blur(e) {
				const that = this;
				
				var data = {
					sortMap:{}
				}
				data.sortMap[e.row.deptNo] = e.row.sort.toString();
				that.api('/admin/department/sort',data ).then(res => {
					if (res.code == 200) {
						that.getlist();
					}
				})
			},
			// 页数改变
			pagebtn(e) {
				const that = this;
				that.pageNo = e;
				that.getlist();
			},
			

			// 获取列表
			getlist() {
				const that = this;
				// /admin/department/list
				that.api('/api/department/list', {
					pageSize: 10,
					pageNo: that.pageNo,
					deptName: that.keyword,
          parentNo: '0'
				}).then(res => {
					that.box = res.data;
					for (var i = 0; i < res.data.length; i++) {
						if (res.data[i].isShow == '1') {
							res.data[i].isShow = true
						} else {
							res.data[i].isShow = false
						}
					}
					that.list = res.data;
					// that.pageNo = res.data.pageNo;
					// this.pageshow = false; //让分页隐藏
					// this.$nextTick(() => { //重新渲染分页
					// 	this.pageshow = true;
					// });
				})
			},
			// 重置搜索
			clearlist() {
				const that = this;
				that.keyword = "";
				that.pageNo=1;
				that.getlist();
			},
			// 按钮开关
			switchbtn(e){
				const that=this;
			
				that.api('/admin/department/status',{deptNo:e.row.deptNo}).then(res=>{
					if(res.code==200){
						that.getlist();
					}
				})
			},
			//删除
			del(id){
				const that=this;
                this.$confirm('您确认要删除该科室信息么?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {

                    that.api('/api/department/get_check_items',{departmentId:id}).then(res=>{
                      if(res.code==200){
                        console.log(res)
                        console.log(res.checkItems)
                        console.log(res.checkItems.length)
                         if(res.checkItems!=null && res.checkItems.length>0){
                           that.$message.error("请先删除该科室下子科室信息!");
                         } else {
                           that.api('/admin/department/delete',{deptNo:id}).then(res=>{
                             if(res.code==200){
                               that.$message.success("删除成功!");
                               that.getlist();
                             }
                           })
                         }
                      }
                    })
                }).catch(() => {
                    that.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

			},
			//跳转查看排班
			goSche(deptno,deptName){
				this.$router.push({name:'departmentsche',query:{no:deptno,dn:deptName}});
			}

		},
		created() {
			const that = this;
			that.getlist();
		}
	}
</script>

<style lang="less" scoped>


</style>
